<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">

        <a-form-model-item v-if="isFinanceManage"
            prop="financial_account_id" label="财务账户">
          <a-select style="width:200px;" allowClear placeholder="选择发券财务账户" 
            v-model="searchForm.financial_account_id" @change="handlerSearch">
            <a-select-option
              v-for="(item, index) in manageFinanceList"
              :key="index"
              :value="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="name" label="券名">
          <a-input style="width:180px" placeholder="券名称 / 内部名" v-model="searchForm.name" />
        </a-form-model-item>
        <!-- <a-form-model-item prop="name" label="串码">
          <a-input style="width:180px" placeholder="请输入串码" v-model="searchForm.exchange_code" />
        </a-form-model-item> -->      
        <a-form-model-item prop="effective_time" label="活动日期">
          <a-range-picker
            style="width:230px"
            v-model="searchForm.effective_time"
            valueFormat="YYYY-MM-DD"
            @change="handlerSearch"
          />
        </a-form-model-item>
        <a-form-model-item prop="status" label="状态" name="status">
          <a-select v-model="searchForm.status" style="width: 100px;" @change="handlerSearch">
            <a-select-option key="" value="">不限</a-select-option>
            <a-select-option key="1" value="1">正常</a-select-option>
            <a-select-option key="0" value="0">停用</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
          <a-button @click="handlerResetForm" style="margin-left: 10px">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="flex justify-between">
        <span class="text-lg font-bold p-3">财务优惠券列表</span>
      </div>
      <div class="flex justify-between mr-2" style="margin-left:10px">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          <div slot="tabBarExtraContent">
            <a-button v-if="hasAuthUpdate" class="p-0" type="link" @click="handlerAdd">新建优惠券</a-button>
          </div>
        </a-tabs>
      </div>
    </div>

    <base-table
      :columnsData="tableColumns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #withstored="{record}">
        <a-tag v-if="record.stored_value_card_use_together==2" class="mr-0" color="blue">允许</a-tag>
        <a-tag v-else class="mr-0" color="red">不允许</a-tag>
      </template>
      <template #status="{text}">
        <a-tag class="mr-0" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '已停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <template v-if="hasAuthUpdate">
          <a-button class="p-0 mr-2" type="link" @click="handlerEdit(record)">详情</a-button>
          <a-button class="p-0 ml-2 mr-2" type="link" style="color:red;"
            v-if="record.status === 1" @click="handlerUpdateStatus(record)">停用</a-button>
          <a-button class="p-0 ml-2 mr-2 red" type="link"
            v-else @click="handlerUpdateStatus(record)">恢复</a-button>
        </template>
        <a-button v-if="hasAuthStock" class="p-0 ml-2 mr-2" type="link" @click="handlerViewCode(record)">库存</a-button>
      </template>
    </base-table>

    <!-- 库存批次 -->
    <a-modal
      v-model="isShowCodeModal"
      width="600px"
      ok-text="关闭"
      @ok="isShowCodeModal=false"
    >
      <div class="mt-4">
        优惠券名称：{{this.curRow.name}}
      </div>
      <div class="mt-4 mb-4">
        <span class="mr-4">总库存：{{totalNumber}}</span>
        <span class="mr-4">剩余库存：{{leftNumber}}</span>
        <a-button class="p-0" type="link"
          @click="isShowAddNumber = true">添加</a-button>
      </div>
      <!-- <a-button @click="handlerOutput" type="primary">导出全部串码</a-button> -->
      <base-table
        rowKey="stock_id"
        :columnsData="columnsCode"
        :tableData="tableDataCode"></base-table>
    </a-modal>

    <!-- 添加库存 -->
    <a-modal
      v-model="isShowAddNumber"
      width="400px"
      ok-text="确定提交"
      @ok="doCreate"
    >
      <div class="flex items-center mt-4">
        <div style="width:120px;">优惠券名称：</div>
        <div>{{this.curRow.name}}</div>
      </div>
      <div class="flex items-center mt-4 mb-4">
        <div style="width:120px;">添加库存数：</div>
        <a-input-number placeholder="请输入数量" style="width:140px;"
          :min="1" v-model="code_number"></a-input-number>
      </div>
    </a-modal>
  </div>
</template>

<script>
import pageData from "./columns"
import { getList, updateStatus, couponStockGetList, couponStockCreate, couponStockCodeExport } from "@/api/activity/coupon.js"
import { 
  getFinanceList,
  isFinanceManage,
} from '@/utils/authData.js'
import { checkAuth } from '@/utils/authData.js'

export default {
  data() {
    return {
      ...pageData,
      activeKey: 0,
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      total: 0,
      searchForm: {
        admin_type: 2, // 写死 财务类发票
        financial_account_id: undefined,
        name: "",
        exchange_code: "",
        effective_time: [],
        effective_time_end: "",
        effective_time_start: "",
        date_type: 0,
        status: "1",
        page: 1,
        page_count: 20,
      },
      tableData: [],
      tableColumns: [],
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "未开始" },
        { type_id: 2, type_name: "进行中" },
        { type_id: 3, type_name: "已结束" },
      ],

      isFinanceManage: isFinanceManage(),
      manageFinanceList: [],

      isShowCodeModal: false,
      curRow: {},
      columnsCode: [
        {
          title: "库存批次",
          dataIndex: "stock_name",
          align: "center",
          width: 80,
        },
        {
          title: "添加时间",
          dataIndex: "add_time",
          align: "center",
        },
        {
          title: "添加数量",
          dataIndex: "code_number",
          align: "center",
          width: 80,
        },
      ],
      tableDataCode: [],
      totalNumber: 0,
      leftNumber: 0,
      code_number: '',

      isShowAddNumber: false,

      hasAuthUpdate: checkAuth("financial-coupon:update"),
      hasAuthStock: checkAuth("financial-coupon:stock"),
    }
  },
  async mounted() {
    if(!this.hasAuthUpdate && !this.hasAuthStock){
      this.tableColumns = this.columns.filter(el=>el.dataIndex!='operation')
    }else{
      this.tableColumns = this.columns
    }

    this.manageFinanceList = await getFinanceList(true)
    if(this.isFinanceManage){
      if(this.manageFinanceList.length == 1){
        this.searchForm.financial_account_id = this.manageFinanceList[0].id
      }
    }
    this.initData()
  },
  methods: {
    async initData() {
      const params = this.searchForm
      if (this.searchForm.effective_time.length > 0) {
        params.effective_time_start = this.searchForm.effective_time[0]
        params.effective_time_end = this.searchForm.effective_time[1]
      }else{
        params.effective_time_start = ""
        params.effective_time_end = ""
      }
      const { data, code } = await getList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    // 分类切换
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.date_type = e
      this.searchForm.page = 1
      this.initData()
    },
    // 搜索
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    // 重置
    handlerResetForm() {
      this.searchForm.name = ""
      this.searchForm.effective_time_end = ""
      this.searchForm.effective_time_start = ""
      this.searchForm.date_type = 0
      this.searchForm.page = 1
      this.searchForm.exchange_code = ""
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    // 新增
    handlerAdd() {
      this.$router.push({
        path: "coupon-add", 
        query: {
          admin_type: this.searchForm.admin_type 
        }
      })
    },
    handlerEdit(row) {
      this.$router.push({ 
        path: "/coupon-detail", 
        query: { id: row.id } 
      })
    },
    // 修改状态
    handlerUpdateStatus(row) {
      updateStatus({
        id: row.id,
        status: row.status === 1 ? 0 : 1,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${row.status === 1 ? "已停用" : "已恢复"}`)
          this.initData()
        }
      })
    },
    handlerRecord(row){
      const newPage = this.$router.resolve({
        name: "coupon-record",
        query: { 
          coupon_id: row.id,
        },
      });
      window.open(newPage.href, "_blank");
    },
    async handlerViewCode(row) {
      this.isShowCodeModal = true
      this.curRow = row

      const params = {
        coupon_id: this.curRow.id
      }
      const { data, code } = await couponStockGetList(params)
      if (code === 0) {
        let totalNumber = 0
        data.list.map(el=>{
          totalNumber += el.code_number
        })
        this.tableDataCode = data.list
        this.totalNumber = totalNumber
        this.leftNumber = totalNumber - data.sales_number
      }
    },
    
    async doCreate() {
      const params = {
        coupon_id: this.curRow.id,
        code_number: this.code_number
      }
      if(!this.code_number) {
        this.$message.error('新增个数不能为空')
        return
      }
      const { code } = await couponStockCreate(params)
      if (code === 0) {
        this.$message.success('生成成功')
        this.handlerViewCode(this.curRow)
        this.isShowAddNumber = false
      }
    },
    //导出
    handlerOutput(row) {
      let params = {}
      if(row.stock_id) {
        params.stock_id = row.stock_id
      }else {
        params.coupon_id = this.curRow.id
      }
			
      couponStockCodeExport(params)
    }
  },
}
</script>

<style>
</style>